import {useContext} from 'react';

import {
  ScrollContext,
  ScrollContextType,
} from '@/services/contexts/ScrollContext';

const useScrollContext = (): ScrollContextType => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error(
      'useScrollContext must be used within a GeoLocationProvider',
    );
  }
  return context;
};

export default useScrollContext;
