import React from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as Close} from '@/assets/icons/nPickHeaderClose.svg';
import {ReactComponent as Title} from '@/assets/icons/nPick.svg';
import {isAndroid, isIos} from '@/utils';

import {PickHeaderWrapper} from './npickheader.styled';

export default function NPickHeader() {
  const navigator = useNavigate();
  return (
    <PickHeaderWrapper android={isAndroid()} ios={isIos()}>
      <button onClick={() => navigator('/map')}>
        <Close />
      </button>
      <Title />
      <div></div>
    </PickHeaderWrapper>
  );
}
