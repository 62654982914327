const brandKeys = {
  getBrandDetail: (payload: any) => ['getBrandDetail', payload],
  getBenefits: (payload: BrandBenefitParams) => ['getBenefits', payload],
  getSqureBrandList: (payload: SquareBrandsParam) => [
    'getSqureBrandList',
    payload,
  ],
};

export default brandKeys;
