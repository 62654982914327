import {useContext} from 'react';

import {
  GeoLocationContextType,
  GeoLocationContext,
} from '@/services/contexts/GeolocationContext';

const useGeoLocation = (): GeoLocationContextType => {
  const context = useContext(GeoLocationContext);
  if (!context) {
    throw new Error('useGeoLocation must be used within a GeoLocationProvider');
  }
  return context;
};

export default useGeoLocation;
