import React from 'react';
import {useNavigate} from 'react-router-dom';

import {isAndroid, isMobile} from '@/utils';
import {ReactComponent as BackBtnIcon} from '@/assets/icons/arrow-right.svg';

import {ContentHeader} from './header.styled';

export default function DefaultHeader() {
  const navigator = useNavigate();
  return (
    <ContentHeader
      android={isAndroid()}
      scrolled={false}
      mobile={isMobile()}
      style={{position: 'sticky', background: '#fff'}}>
      <button onClick={() => navigator('/map')}>
        <BackBtnIcon fontSize={20} />
        <></>
      </button>
    </ContentHeader>
  );
}
