import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IoIosSearch} from 'react-icons/io';
import {useSetRecoilState} from 'recoil';

import {BrandAddr2, BrandAddr3, BrandAreaListPage} from '@/store';
import useGeoLocation from '@/hooks/useGeoLocation';

import useLogger, {Action} from '../../hooks/useLogger';
import styles from './styles.module.scss';
import {useScroll} from '../../hooks/useScroll';

type NavType =
  | 'home'
  | 'recommend'
  | 'comm'
  | 'my'
  | 'collection'
  | 'brand'
  | 'search';
interface Navigator {
  txt: string;
  icon: NavType;
  link: string;
}
interface Props {
  style?: React.CSSProperties;
}

export default function BottomNavigation({style}: Props) {
  const {resetScroll} = useScroll();
  const {getCurrentPosition, setIsAreaSelected} = useGeoLocation();
  const navigator = useNavigate();
  const sender = useLogger();
  const [navType, setNavType] = useState<NavType>('home');
  const {t} = useTranslation(['layouts'], {keyPrefix: 'BottomNavigation'});
  const setBrandsPage = useSetRecoilState(BrandAreaListPage);
  const setAddr2 = useSetRecoilState(BrandAddr2);
  const setAddr3 = useSetRecoilState(BrandAddr3);
  const nav: Navigator[] = [
    {
      txt: t('home'),
      icon: 'home',
      link: '/home',
    },
    {
      txt: '할인권',
      icon: 'search',
      link: '/search?from=home',
    },
    {
      txt: '내주변',
      icon: 'brand',
      link: '/map',
    },
    {
      txt: '셀렉숍',
      icon: 'collection',
      link: '/collection/list',
    },
    {
      txt: t('my'),
      icon: 'my',
      link: '/user',
    },
  ];

  const changeNav = async (type: NavType, link: string) => {
    if (type === 'collection') {
      sender({
        _msg: 'collection_pageview',
        _screen: window.location.pathname,
        _action: Action.CLICK,
      });
    }
    if (link === '/brands') {
      setBrandsPage(0);
      setIsAreaSelected(false);
      setAddr2(16);
      setAddr3(undefined);
    }

    if (link === '/map') {
      setIsAreaSelected(false);
      getCurrentPosition();
    }
    resetScroll();
    setNavType(type);
    navigator(link);
  };

  useEffect(() => {
    const path = window.location.pathname;
    const search = path.indexOf('search') > -1 ? 'search' : '';
    let selectedNav = nav.find(n => path.indexOf(n.link) > -1);
    if (search.length > 0) {
      selectedNav = nav[1];
    }
    if (selectedNav) {
      setNavType(selectedNav.icon);
    }
  }, [window.location.pathname]);

  return (
    <div className={styles.wrapper} style={style}>
      {nav.map(n => {
        return (
          <button
            className={styles.menu}
            key={n.txt}
            onClick={() => changeNav(n.icon, n.link)}>
            {n.icon === 'search' ? (
              <IoIosSearch
                color={navType === n.icon ? '#000' : '#9F9CA3'}
                size={'20px'}
              />
            ) : (
              <span
                className={`${styles.icon} ${
                  styles[
                    `${`${n.icon}`}-${
                      navType === n.icon ? 'active' : 'deactive'
                    }-icon`
                  ]
                } `}>
                &nbsp;
              </span>
            )}
            <span
              className={`${styles.txt} ${
                navType === n.icon ? styles.active : ''
              }`}>
              {n.txt}
            </span>
          </button>
        );
      })}
    </div>
  );
}
