import React, {useEffect, useState} from 'react';
import {useSetRecoilState, useRecoilState} from 'recoil';
import './App.css';
import {useRoutes} from 'react-router-dom';
import {StatusBar} from '@capacitor/status-bar';
import {Capacitor} from '@capacitor/core';
import {SafeAreaController} from '@aashu-dubey/capacitor-statusbar-safe-area';

import routes from './routes';
import LoginModal from './components/common/LoginModal';
import Toast from './components/common/Toast';
import MagazineModal from './components/common/MagazineModal';
import {userDetail} from './services/user';
import {user, UserInfo} from './store/user';
import useFirebase from './hooks/useFirebase';
import Menu from './layouts/Menu';
import CommunityLoginAlert from './components/common/CommunityLoginAlert';
import CommunityWriteAlert from './components/common/CommunityWriteAlert';
import CommunityEditCancelAlert from './components/common/CommunityEditCancelAlert';
import useStorage from './hooks/useStorage';
import usePushNotification from './hooks/usePushNotification';
import InstallAlert from './components/installAlert';
import useAppScheme from './hooks/useAppScheme';
import NotificationAlert from './components/common/NotificationAlert';
import useNativeNavigator from './hooks/useNativeNavigator';
import DuplicatedEmailModal from './components/common/DuplicatedEmailModal';
import {IsOpenCertTryError} from './store';
import AuthModal from './components/common/AuthModal';
import useSplash from './hooks/useSplash';
import useLogger, {Action} from './hooks/useLogger';

function App() {
  const router = useRoutes(routes);
  const {getToken} = useStorage();
  const {registerNotifications, addListeners, getChannels, createChannel} =
    usePushNotification();
  const {initAnalytics, analytics} = useFirebase();
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const setAccessToken = useSetRecoilState(user);
  const [isOpenCertTryError, setIsOpenCertTryError] =
    useRecoilState(IsOpenCertTryError);
  const [isInit, setIsInit] = useState(false);
  const {show} = useSplash();
  const sender = useLogger();
  // if (process.env.REACT_APP_ENV !== 'development') {
  //   console.log = () => {
  //     /**/
  //   };

  //   console.debug = () => {
  //     /**/
  //   };

  //   console.info = () => {
  //     /**/
  //   };

  //   console.warn = () => {
  //     /**/
  //   };
  // }

  useAppScheme();
  useNativeNavigator();
  const injectSafeAreaVariables = () => {
    SafeAreaController.injectCSSVariables();
  };
  const init = async () => {
    const myUrl = document.location.href.split('://')[1].split('/')[0];
    if (myUrl === 'about.knewnew.co.kr' || myUrl === 'www.knewnew.co.kr') {
      window.location.replace('https://knewnew.co.kr');
    }
    try {
      const accessToken = await getToken();
      if (accessToken) {
        const res = await userDetail(accessToken);
        setUserInfo(res.data);
        setAccessToken(accessToken);
      }
      setIsInit(true);
      // hide();
    } catch (e) {
      setIsInit(true);
      // hide();
    }
    sender({_screen: '/', _action: Action.INIT, _data: {event: 'NewAccess'}});
  };

  const deviceInit = async () => {
    if (Capacitor.getPlatform() !== 'web') {
      show();
      StatusBar.setOverlaysWebView({overlay: true});
      addListeners();
      registerNotifications();
      const channels = await getChannels();
      const defaultChannel = channels.find(c => c.id === 'knewnew_app_channel');
      if (!defaultChannel) {
        createChannel();
      }
    }
    injectSafeAreaVariables();
  };

  useEffect(() => {
    deviceInit();
    if (userInfo.id === -1) {
      init();
    }
    if (!analytics) {
      initAnalytics();
    }
    window.history.scrollRestoration = 'auto';
  }, []);
  return (
    <div className="App">
      {isInit && router}
      <LoginModal />
      <CommunityLoginAlert />
      <CommunityWriteAlert />
      <CommunityEditCancelAlert />
      <Toast />
      <MagazineModal />
      <Menu />
      <InstallAlert />
      <NotificationAlert />
      <DuplicatedEmailModal />
      <AuthModal
        txt={
          <>
            1일 인증 가능 횟수를 초과했습니다. <br />
            <span>24시간</span> 이후에 다시 시도해 주세요.
          </>
        }
        isOpen={isOpenCertTryError}
        setIsOpen={setIsOpenCertTryError}
        btnType={'error'}
        action={() => setIsOpenCertTryError(false)}
      />
      <canvas
        id="fakeCanvas"
        style={{
          visibility: 'hidden',
          position: 'fixed',
          top: '-999999px',
        }}></canvas>
    </div>
  );
}

export default App;
