const APIConstants = {
  REST: {
    get BRAND_DETAIL() {
      return `/magazine/brands/group`;
    },
    get BRAND_BENEFITS() {
      return `/magazine/product/`;
    },
    get BOOKMARK() {
      return '/magazine/brands/group/bookmark/';
    },
    get SQUARE_BRANDS() {
      return '/magazine/brands/list/square/';
    },
  },
};

export default APIConstants;
