import React, {createContext, MutableRefObject, useRef} from 'react';
import {useRecoilState} from 'recoil';

import {ScrollPositions} from '@/store';

type ScrollPosType = {
  [key: string]: {x: number; y: number};
};

export interface ScrollContextType {
  scrollRef: MutableRefObject<HTMLElement | null>;
  scrollPosList: ScrollPosType;
  setScrollPosList: (v: ScrollPosType) => void;
}

export const ScrollContext = createContext<ScrollContextType | undefined>(
  undefined,
);

export const ScrollContextProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const [scrollPosList, setScrollPosList] = useRecoilState(ScrollPositions);
  const scrollRef = useRef<HTMLElement | null>(null);

  return (
    <ScrollContext.Provider
      value={{
        scrollPosList,
        scrollRef,
        setScrollPosList,
      }}>
      {children}
    </ScrollContext.Provider>
  );
};
