import {AxiosResponse} from 'axios';

import APIConstants from './ApiConstants';
import {default as api} from './ApiWrapper';

const getBrandDetails = async (id: number) => {
  const url = `${APIConstants.REST.BRAND_DETAIL}/${id}`;
  const res: AxiosResponse<BrandParentInfo> = await api.get(url);
  return res.data;
};

const getBrandBenefits = async (payload: BrandBenefitParams) => {
  const url = APIConstants.REST.BRAND_BENEFITS;
  const res: AxiosResponse<any> = await api.get(url, {
    params: payload,
  });
  return res.data;
};

const getSqureBrandList = async (payload: SquareBrandsParam) => {
  const url = APIConstants.REST.SQUARE_BRANDS;
  const res: AxiosResponse<any> = await api.get(url, {
    params: payload,
  });
  return res.data;
};

const doBookmark = async (payload: {id: number; bookmark: boolean}) => {
  const url = APIConstants.REST.BOOKMARK + `${payload.id}/`;
  await api.post(url, {
    isBookmark: payload.bookmark,
  });
};

export {getBrandDetails, getBrandBenefits, doBookmark, getSqureBrandList};
