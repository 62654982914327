import {useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {ScrollPositions} from '@/store';

import useScrollContext from './useScrollContext';

export const useScroll = () => {
  const location = useLocation();
  const {scrollRef} = useScrollContext();
  const [scrollPosList, setScrollPosList] = useRecoilState(ScrollPositions);
  const onScroll = () => {
    requestAnimationFrame(() => {
      const target = scrollRef.current || window;
      const pageXOffset =
        target instanceof Window ? target.pageXOffset : target.scrollLeft;
      const pageYOffset =
        target instanceof Window ? target.pageYOffset : target.scrollTop;
      const existedSP = {...scrollPosList};
      existedSP[`${location.pathname}${location.search}`] = {
        x: pageXOffset,
        y: pageYOffset,
      };
      setScrollPosList(() => {
        return existedSP;
      });
    });
  };
  const syncScroll = () => {
    const target = scrollRef.current || window;
    const sp = scrollPosList[`${location.pathname}${location.search}`];
    if (sp && sp.y > 0) {
      setTimeout(() => {
        if (target === window) {
          window.scrollTo(sp.x, sp.y);
        } else if (target === scrollRef.current) {
          target.scrollTop = sp.y;
          target.scrollLeft = sp.x;
        }
      }, 100);
    } else {
      setTimeout(() => {
        if (target === window) {
          window.scrollTo(0, 0);
        } else if (target === scrollRef.current) {
          target.scrollTop = 0;
          target.scrollLeft = 0;
        }
      }, 100);
    }
  };
  const hasScrollPosition = () => {
    const keys = Object.keys(scrollPosList);
    return (
      keys.indexOf(`${location.pathname}${location.search}`) > -1 &&
      location.state?.scrollEnabled
    );
  };
  const checkScrolled = (path: string) => {
    return scrollPosList[path] && scrollPosList[path].y > 0;
  };
  const resetScroll = async (path?: string) => {
    const _sp = JSON.parse(JSON.stringify(scrollPosList));
    const sp = _sp[path ? path : `${`${location.pathname}${location.search}`}`];
    if (sp) {
      _sp[path ? path : `${location.pathname}${location.search}`] = {
        x: 0,
        y: 0,
      };
      setScrollPosList(_sp);
    }
    return;
  };
  const setCombinedRef = useCallback(
    (
      element: HTMLElement | null,
      externalRef?: React.Ref<HTMLElement | null>,
    ) => {
      scrollRef.current = element;

      if (typeof externalRef === 'function') {
        externalRef(element); // 콜백 ref 형태로 전달
      } else if (externalRef && 'current' in externalRef) {
        (externalRef as React.MutableRefObject<HTMLElement | null>).current =
          element; // MutableRefObject로 전달
      }
    },
    [],
  );

  useEffect(() => {
    const target = scrollRef.current || window;
    target.addEventListener('scroll', onScroll);
    return () => {
      target.removeEventListener('scroll', onScroll);
    };
  }, [scrollRef]);

  return {
    scrollPosList,
    syncScroll,
    hasScrollPosition,
    checkScrolled,
    resetScroll,
    scrollRef,
    setCombinedRef,
  };
};
