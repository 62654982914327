import React, {useEffect, useState, useRef} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {
  AddressSelectorList,
  CommunityAddress1,
  CommunityAddress2,
  CommunityAddressTxt,
  CommunityListArgs,
  FamousAddressList,
  isOpenAddressSelector,
} from '../../../store';
import FilterModalHeader from '../../common/FilterModal/FilterModalHeader';
import styles from './styles.module.scss';

interface Props {
  addressList: Address[];
  updateAddress: (
    address2: string,
    address3: string,
    coords?: number[],
  ) => void;
  setIsGeolocationModalOpen?: (is: boolean) => void;
  setIsLoad?: (is: boolean) => void;
  callback?: () => void;
}

export default function AddressSelector({addressList, updateAddress}: Props) {
  const [isOpen, setIsOpen] = useRecoilState(isOpenAddressSelector);
  const [selectedAddress1, setSelectedAddress1] = useState<string>('0');
  const [selectedAddress2, setSelectedAddress2] = useState<string>('');
  const setAddress1 = useSetRecoilState(CommunityAddress1);
  const setAddress2 = useSetRecoilState(CommunityAddress2);
  const setAddressTxt = useSetRecoilState(CommunityAddressTxt);
  const famousAddressList = useRecoilValue(FamousAddressList);
  const [addressGroups, setAddressGroups] = useRecoilState(AddressSelectorList);
  const [args, setArgs] = useRecoilState(CommunityListArgs);
  const [selectedAreaCoords, setSelectedAreaCoords] = useState<number[]>([]);
  const close = () => {
    setIsOpen(false);
    initFn();
  };
  const initFn = () => {
    setSelectedAddress1('0');
    setSelectedAddress2('');
    setArgs({
      address1: '',
      address2: '',
      page: 0,
    });
    setIsOpen(false);
  };
  const complete = () => {
    setAddress1(selectedAddress1);
    setAddress2(selectedAddress2);
    setArgs({
      ...args,
      address1: selectedAddress1,
      address2: selectedAddress2,
      page: 0,
    });
    setAddressTxt(`${selectedAddress2}`);
    updateAddress(selectedAddress1, selectedAddress2, selectedAreaCoords);
    setIsOpen(false);
  };
  const clickAddress1 = (txt: string) => {
    setSelectedAddress1(txt);
  };
  const clickAddress2 = (txt: string, coords?: number[]) => {
    setSelectedAddress2(txt);
    if (coords) {
      setSelectedAreaCoords(coords);
    }
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.querySelector('.addressGroup1')?.addEventListener('scroll', () => {
      console.log('scrolled');
    });
    document
      .querySelector('.addressGroup1')
      ?.addEventListener('touchmove', () => {
        console.log('in touch move');
      });
    return () => {
      document
        .querySelector('.addressGroup1')
        ?.removeEventListener('scroll', () => {
          console.log('scrolled');
        });
    };
  }, []);

  useEffect(() => {
    if (addressList) {
      let _adddressGroup: AddressSelectorInfo[] = [];
      addressList.forEach(address => {
        _adddressGroup.push({
          groupName: address.address,
          addressType: 1,
          address1: [address.id],
          addressList: (address.child as Address[]).map(o => {
            return {
              txt: o.address,
              lon: o.lon,
              lat: o.lat,
              address2: [o.id],
            };
          }),
        });
      });
      _adddressGroup = _adddressGroup.map(group => {
        return {
          ...group,
          addressList: [...group.addressList],
        };
      });
      setAddressGroups(_adddressGroup);
    }
  }, [addressList]);

  return (
    <div className={`modal-wrapper ${isOpen ? '' : styles.hide}`}>
      <div
        className={`modal-contents category ${styles.address}`}
        ref={wrapperRef}>
        <FilterModalHeader
          txt={'지역 선택'}
          close={() => close()}
          hasInit={true}
          withPadding={true}
          initFn={() => initFn()}
          fnTxt=""
        />
        {isOpen && (
          <div className={`category-modal-body ${styles.address_selector}`}>
            <div className={`${styles.address1_group} addressGroup1`}>
              <div
                key={`address_group1_favorit`}
                className={selectedAddress1 === '0' ? `${styles.toggled}` : ''}
                onClick={() => clickAddress1('0')}
                style={{cursor: 'pointer'}}>
                🔥 인기지역
              </div>
              {addressGroups.map((group, idx) => {
                return (
                  <div
                    key={`address_group1_${idx}`}
                    className={
                      selectedAddress1 === group.groupName
                        ? `${styles.toggled}`
                        : ''
                    }
                    onClick={() => clickAddress1(group.groupName)}
                    style={{cursor: 'pointer'}}>
                    {group.groupName}
                  </div>
                );
              })}
            </div>
            <div className={styles.address2_group}>
              {selectedAddress1 === '0' ? (
                <div className={styles.famousArea}>
                  {famousAddressList.map((area, idx) => {
                    return (
                      <button
                        key={`famous_area_${idx}`}
                        className={`${styles.areaBtn} ${
                          selectedAddress2 === area.address
                            ? styles.activeArea
                            : ''
                        }`}
                        onClick={() => {
                          setSelectedAddress2(area.address);
                          setSelectedAreaCoords([
                            area.lat || -1,
                            area.lon || -1,
                          ]);
                        }}>
                        {area.address}
                      </button>
                    );
                  })}
                </div>
              ) : (
                <ul>
                  {selectedAddress1 &&
                    selectedAddress1.length > 0 &&
                    addressGroups
                      .find(ag => ag.groupName === selectedAddress1)
                      ?.addressList.map((o, i) => {
                        return (
                          <li
                            key={`address_group2_${i}`}
                            className={
                              selectedAddress2 === o.txt ? 'active' : ''
                            }
                            onClick={() =>
                              clickAddress2(o.txt, [o.lat ?? -1, o.lon ?? -1])
                            }
                            style={{cursor: 'pointer', justifyContent: 'left'}}>
                            {o.txt}
                          </li>
                        );
                      })}
                </ul>
              )}
            </div>
          </div>
        )}
        <div className={`filter-modal-footer ${styles.address_footer}`}>
          <button
            className="modal btn black-filled"
            disabled={
              !(selectedAddress1.length > 0 && selectedAddress2.length > 0)
            }
            onClick={() => complete()}
            style={{
              background: !(
                selectedAddress1.length > 0 && selectedAddress2.length > 0
              )
                ? '#E9E7EC'
                : '#000',
            }}>
            검색
          </button>
        </div>
      </div>
    </div>
  );
}
